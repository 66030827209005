import { init } from '@jill64/sentry-sveltekit-cloudflare/client'
import { PUBLIC_SENTRY_ENVIRONMENT } from '$env/static/public'

const onError = init(
    'https://407d1af25bb349ffd513bc3da24fddd0@o439520.ingest.us.sentry.io/4506830123040768',
    {
        sentryOptions: {
            tracesSampleRate: 0.1,
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1,
            environment: PUBLIC_SENTRY_ENVIRONMENT,
        }
    }
)

export const handleError = onError((e, sentryEventId) => {
    // Your Error Handler
})